/* ------------------------------------------------------------------------------------------------ */
/* Fonts */
/* ------------------------------------------------------------------------------------------------ */

@font-face {
  font-family: 'proximanova-regular';
  src: url('../fonts/proximanova-regular.eot'),url('../fonts/proximanova-regular.eot?#iefix') format('embedded-opentype'),url('../fonts/proximanova-regular.woff') format('woff'),url('../fonts/proximanova-bold.ttf') format('truetype'),url('../fonts/proximanova-regular.svg#proxima_nova_rgregular') format('svg');
  font-weight: normal;
  font-style: normal; }

@font-face {
  font-family: 'proximanova-bold';
  src: url('../fonts/proximanova-bold.eot'),url('../fonts/proximanova-bold.eot?#iefix') format('embedded-opentype'),url('../fonts/proximanova-bold.woff') format('woff'),url('../fonts/proximanova-bold.ttf') format('truetype'),url('../fonts/proximanova-bold.svg#proxima_nova_bold') format('svg');
  font-weight: normal;
  font-style: normal; }

@font-face {
  font-family: 'proximanova-semibold';
  src: url('../fonts/proximanova-semibold.eot'),url('../fonts/proximanova-semibold.eot?#iefix') format('embedded-opentype'),url('../fonts/proximanova-semibold.woff') format('woff'),url('../fonts/proximanova-semibold.ttf') format('truetype'),url('../fonts/proximanova-semibold.svg#proxima_novasemibold') format('svg');
  font-weight: normal;
  font-style: normal; }

@font-face {
  font-family: 'proximanova-light';
  src: url('../fonts/proximanova-light.eot'),url('../fonts/proximanova-light.eot?#iefix') format('embedded-opentype'),url('../fonts/proximanova-light.woff') format('woff'),url('../fonts/proximanova-light.ttf') format('truetype'),url('../fonts/proximanova-light.svg#proxima_novalight') format('svg');
  font-weight: normal;
  font-style: normal; }

@font-face {
  font-family: 'utopia';
  src: url('../fonts/UtopiaStd-Regular.eot'),url('../fonts/UtopiaStd-Regular.eot?#iefix') format('embedded-opentype'),url('../fonts/UtopiaStd-Regular.woff') format('woff'),url('../fonts/UtopiaStd-Regular.ttf') format('truetype'),url('../fonts/UtopiaStd-Regular.svg#proxima_novalight') format('svg');
  font-weight: normal;
  font-style: normal; }


