/* ------------------------------------------------------------------------------------------------ */
/* Content */
/* ------------------------------------------------------------------------------------------------ */
body {
  padding-top: 175px;
  position: relative;
  height: auto !important;
  @include maxtablet {
    padding-top: 65px; } }

.masthead {
  .container {
    @include maxtablet {
      padding: 0;
      width: 100%; } }
  .owl-dots {
    position: absolute;
    bottom: 15px;
    left: 0;
    right: 0; }
  .item {
    cursor: pointer;
    display: block;
    position: relative;
    padding: 18% 0;
    @include backgroundSize(cover);
    background-position: center;
    background-color: #eee;
    @include mobile {
      padding: 30% 0; }
    &.video {
      &:before {
        content: "";
        position: absolute;
        width: 58px;
        height: 73px;
        left: calc(50% - 29px);
        top: calc(50% - 36.5px);
        @include background("../images/play.png",cover);
        z-index: 2;
        @include mobile {
          width: 28px;
          height: 36px;
          left: calc(50% - 14px);
          top: calc(50% - 18px); } } }
    &:after {
      background: rgba(#000,.2);
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      @include transition(all .5s);
      opacity: 0; }
    &:hover:after {
      opacity: 1; } } }

.hot-product {
  @extend .ptd60; }

.stock-status {
  font-size: 14px;
  color: #777;
  display: block;
  &.out {
    color: #D0021B; } }
.product {
  position: relative;
  h2 {
    @extend .font-size-21,.text-semibold;
    text-align: center;
    margin: 0 0 15px; }
  .container {
    position: relative; }
  .items {
    @include mintablet {
      [class*="col-"] {
        position: relative;
        overflow: hidden;
        &:hover {
          overflow: visible; }
        .item {
          position: absolute;
          top: 0;
          &:hover {
            z-index: 6;
            height: auto !important; } } } }
    [class*="col-"] {
      padding: 0;
      @include responsive(0,1200px) {
        min-height: 451px; } }
    [class*="col-"],.item {
      @include mobile {
        height: auto !important; }
      &:nth-child(3n+1) {
        clear: left; } }
    .empty {
      color: $orange;
      height: 600px;
      text-align: center;
      @include center;
      .wrap {
        margin: auto; }
      .fa {
        font-size: 120px;
        margin: 0 0 30px; } }
    .item {
      padding: 30px;
      background: #fff;
      border-radius: 4px;
      @include transition(all .5s);
      a {
        display: block; }
      .img {
        height: 270px;
        position: relative;
        @include center;
        img {
          display: block;
          margin: auto;
          max-height: 268px; }
        .front-side {
          visibility: hidden;
          opacity: 0;
          position: absolute;
          left: 0;
          top: 0;
          bottom: 0;
          right: 0;
          bacground: #fff;
          @include center;
          z-index: 2;
          transition: opacity .5s; }
        @include tablet {
          height: 150px; }
        @include mobile {
          height: 220px; } }
      .title {
        margin: 20px 0 10px;
        color: #212121;
        padding-bottom: 8px;
        border-bottom: 1px solid #e2e2e2;
        font-size: 18px;
        line-height: 1.5;
        @extend .text-semibold;
        &:hover {
          color: $orange; } }
      b {
        @include transition(all .5s);
        span {
          color: #D0021B;
          display: inline-block;
          vertical-align: top;
          margin-left: 10px;
          @include tablet {
            margin-left: 10px; } } }
      p {
        margin: 0 0 7px;
        &+ p {
          margin: 0 0 15px; } }
      .action,.btns,b {
        @include mintablet {
          opacity: 0;
          top: 30px;
          visibility: hidden; } }
      .tag {
        position: absolute;
        top: 20px;
        left: 20px;
        background-color: #212121;
        padding: 8px;
        color: #f3b000;
        font-size: 14px;
        line-height: 14px;
        text-transform: uppercase;
        // &.new
        //   background-color: #F39400
        // &.best-seller
        //   background-color: #05c80f
        // &.limited
        //   background-color: #0619cb
        // &.last-stock
        //   background-color: #f6380c
        // &.popular
 }        //   background-color: #366513
      .tags {
        position: absolute;
        top: 20px;
        left: 20px;
        z-index: 5;
        .tag {
          position: static;
          display: inline-block;
          margin-right: 10px; } }
      .close {
        position: absolute;
        top: 20px;
        right: 20px;
        font-size: 36px;
        line-height: 1;
        color: #777;
        opacity: 1;
        z-index: 5;
        &:hover {
          color: #212121; } }
      &:hover {
        box-shadow: none;
        .img {
          .front-side {
            opacity: 1;
            visibility: visible; } }
        .action,.btns {
          opacity: 1;
          top: 0;
          visibility: visible; }
        b {
          opacity: 1;
          visibility: visible; } } } }
  &.widget {
    @include responsive(0,992px) {
      h2 {
        text-align: left; }
      .items {
        .item {
          .img {
            height: 180px; } } } } } }


.product-page {
  @extend .pt10,.pb100;
  .items {
    .item {
      padding: 20px; } } }
.lmore {
  clear: both;
  @extend .mt50;
  color: #777;
  text-align: center;
  display: block; }

.jscroll-added {
  clear: both; }
.jscroll-loading {
  text-align: center;
  @extend .pt50;
  .loading {
    display: inline-block;
    &:before {
      content: "";
      width: 24px;
      height: 24px;
      display: inline-block;
      margin-right: 10px;
      border: 2px solid #212121;
      border-radius: 100%;
      border-right-color: #fff;
      -webkit-animation-name: rotate;
      animation-name: rotate;
      -webkit-animation-duration: 1s;
      animation-duration: 1s;
      -webkit-animation-iteration-count: infinite;
      animation-iteration-count: infinite;
      -webkit-animation-timing-function: linear;
      animation-timing-function: linear;
      vertical-align: top; } } }
.action,.btns {
  @include transition(all .5s);
  margin: 30px 0 0;
  border-radius: 4px;
  background: #212121;
  height: 50px;
  overflow: hidden;
  position: relative;
  .btn.btn-transparent {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    color: #fff;
    line-height: 50px;
    background: none;
    border: none;
    position: relative;
    @include transition(all .5s);
    display: block;
    &:hover {
      background: #555; }
    &.disabled {
      background: #B9B9B9;
      opacity: 1; } }
  .count {
    position: absolute;
    top: 100%;
    left: 0;
    right: 0;
    @include transition(all .5s);
    span {
      display: block;
      line-height: 50px;
      color: #fff;
      text-align: center; }
    i {
      font-style: normal;
      position: absolute;
      top: 0;
      left: 0;
      line-height: 50px;
      padding: 0 15px;
      color: #fff;
      font-size: 21px;
      cursor: pointer;
      &:before {
        content: "-"; }
      &.plus {
        left: auto;
        right: 0;
        &:before {
          content: "+"; } }
      &:hover {
        background: #555; } } }
  &.loading {
    .btn {
      &:before {
        content: "";
        width: 22px;
        height: 22px;
        display: inline-block;
        margin-right: 10px;
        border: 2px solid #fff;
        border-radius: 100%;
        border-right-color: #212121;
        position: absolute;
        top: 12px;
        left: 15px;
        -webkit-animation-name: rotate;
        animation-name: rotate;
        -webkit-animation-duration: 1s;
        animation-duration: 1s;
        -webkit-animation-iteration-count: infinite;
        animation-iteration-count: infinite;
        -webkit-animation-timing-function: linear;
        animation-timing-function: linear; }
      &:hover:before {
        border-right-color: #555; } } }

  &.added {
    .btn {
      top: -100%; }
    .count {
      top: 0; } } }

.filter {
  @include maxtablet {
    position: absolute;
    left: -33.3%;
    @include transition(all .5s);
    top: 0;
    z-index: 4;
    background: #fff;
    &.open {
      left: -30px; } }
  @include maxtablet {
    top: 60px;
    position: fixed;
    left: -91%;
    width: 90%;
    height: calc(100% - 60px);
    &.open {
      left: -30px; }
    &.nf {
      top: 0;
      height: 100%; } }
  .arrow {
    position: absolute;
    left: calc(100% - 1px);
    top: 10px;
    padding: 0 15px;
    background: #eee;
    line-height: 50px;
    z-index: 5;
    border-radius: 0 10px 10px 0;
    &:before {
      content: "\f0b0";
      font-family: fontAwesome; }
    @include minltablet {
      display: none; } }
  .box {
    padding: 20px 0;
    box-shadow: 0 1px 4px 0 rgba(0,0,0,0.5);
    background: fff;
    border-radius: 4px;
    @include maxtablet {
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      padding-left: 20px;
      overflow: scroll; }
    h3 {
      @extend .text-semibold,.font-size-21;
      padding: 0 20px;
      margin: 0 0 15px; }
    ul {
      list-style: none;
      padding: 0 20px 15px;
      border-bottom: 1px solid #e2e2e2;
      margin: 0 0 20px;
      li {
        display: block; }
      & + .collapse-filter {
        padding-top: 0; } }
    .collapse-filter {
      padding: 20px 0;
      border-bottom: 1px solid #e2e2e2;
      h3 {
        cursor: pointer;
        margin: 0;
        position: relative;
        &:after {
          content: "\f196";
          width: 13px;
          height: 16px;
          font-family: FontAwesome;
          font-size: 16px;
          line-height: 16px;
          color: #777777;
          position: absolute;
          top: 7px;
          right: 20px; } }
      ul {
        padding-bottom: 0;
        border: none;
        margin: 0;
        display: none;
        li:first-child {
          margin-top: 15px; } }
      &:first-child {
        padding-top: 0; }
      &:nth-last-child(1) {
        padding-bottom: 0;
        border: none; }
      &.open {
        h3:after {
          content: "\f147"; } } }

    label {
      cursor: pointer;
      position: relative;
      span {
        display: block;
        line-height: 32px;
        padding-left: 35px;
        &:before {
          content: "";
          width: 20px;
          height: 20px;
          border: 1px solid #e2e2e2;
          border-radius: 3px;
          position: absolute;
          top: 6px;
          left: 0; }
        &:after {
          content: "\f00c";
          font-family: fontAwesome;
          line-height: 32px;
          top: 0;
          left: 0;
          width: 20px;
          text-align: center;
          color: #fff;
          position: absolute; } }
      input {
        position: absolute;
        top: 0;
        left: 0;
        width: 0;
        visibility: hidden;
        &:checked + span {
          &:before {
            background: #f3b000; } } } } }
  .slider.slider-horizontal {
    margin: 30px 20px 20px;
    width: calc(100% - 40px);
    height: auto;
    .slider-track {
      height: 2px;
      background-color: #B6B6B6;
      border-radius: 2px;
      top: 0; }
    .slider-selection {
      background: #f3b000; }
    .slider-handle {
      width: 21px;
      height: 21px;
      background: #FFF;
      box-shadow: 0 3px 1px 0 rgba(0,0,0,0.05), 0 2px 2px 0 rgba(0,0,0,0.1), 0 3px 3px 0 rgba(0,0,0,0.05);
      border: 0.5px solid rgba(0,0,0,0.1);
      top: -4px; }
    .tooltip {
      position: static;
      margin-left: 0 !important;
      display: inline-block;
      width: 49%;
      vertical-align: top;
      margin-top: 10px;
      .tooltip-inner {
        background: none;
        color: #212121;
        font-size: 14px;
        line-height: 17px;
        text-align: left;
        &:before {
          content: "From";
          display: block;
          font-size: 12px;
          line-height: 14px;
          font-weight: 600; } }
      .tooltip-arrow {
        display: none; }
      &.tooltip-max {
        .tooltip-inner {
          &:before {
            content: "To"; } } } } } }




.brand {
  padding-bottom: 100px;
  @include mobile {
    padding-bottom: 30px; }
  .item {
    display: block;
    position: relative;
    overflow: hidden;
    margin: 15px;

    @include mobile {
      margin: 15px 0; }
    @include mintablet {
      width: calc(33.3% - 30px); }
    &:before {
      content: "";
      padding: 50% 0;
      display: block; }
    .img {
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      @include center;
      img {
        margin: auto;
        display: block;
        width: auto !important;
        height:auto !important {}
        max-width: 80%;
        filter: grayscale(100%); } }
    .bg {
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      @include backgroundSize(cover);
      background-position: center;
      background-color: #212121;
      @include transform(scale(1.2));
      opacity: 0;
      @include transition(all .5s); }
    &:hover {
      .bg {
        opacity: 1;
        @include transform(scale(1)); }
      .img {
        opacity: 0.8; } } }
  .slider-xs {
    @include mobile {
      margin: 0; } }
  .owl-dots {
    .owl-dot.active {
      background: $orange; } } }

.breadcrumb {
  background: none;
  padding: 8px 0;
  &> li + li:before {
    content: "// ";
    color: #777; }
  li {
    a {
      color: #777;
      font-size: 14px; } } }

.single {
  @include mobile {
    padding-top: 30px; }
  .row.main {
    padding-bottom: 60px; }
  .thumbnails {
    float: left;
    a {
      width: 100px;
      height: 100px;
      @include backgroundSize(90% auto);
      background-repeat: no-repeat;
      background-position: center;
      border: 1px solid #d8d8d8;
      display: block;
      margin: 0 10px 10px 0;
      &.active {
        border: 2px solid #B9B9B9; } } }
  .img-box {
    &:after {
      content: "";
      display: table;
      clear: both; }
    .img {
      float: left;
      width: calc(100% - 110px); }
    @include mobile {
      position: relative;
      padding-bottom: 60px;
      .thumbnails {
        float: none;
        position: absolute;
        bottom: 0;
        left: 0;
        a {
          width: 50px;
          height: 50px;
          display: inline-block;
          margin-bottom: 0; } }
      .img {
        float: none;
        width: 100%; } } }
  .who-wearing-them {
    @media(min-width: 768px) {
      margin-top: 30px; }
    clear: both;
    h3 {
      margin: 0 0 15px; }
    .slider {
      margin: 0 -15px;
      position: relative;
      @include mobile {
        width: calc(100% + 30px);
        margin-bottom: 30px; }
      .owl-prev,.owl-next {
        position: absolute;
        top: calc(50% - 37px);
        left: 15px;
        line-height: 40px;
        font-size: 30px;
        background: rgba(#fff,.5);
        padding: 0 5px; }
      .owl-next {
        left: auto;
        right: 15px; } }

    .item-slider {
      padding: 0 15px;
      b {
        font-weight: 600; } }
    .img-item {
      padding: 30% 0;
      @include backgroundSize(cover);
      background-position: center;
      background-color: #eee;
      margin: 0 0 10px;
      @include mobile {
        padding: 20% 0; } } }


  .product-name {
    margin: 0 0 30px;
    h2 {
      @extend .font-size-26,.text-semibold;
      margin: 0; }
    p {
      font-size: 14px;
      color: #777; } }
  .price {
    @extend .font-size-26,.text-semibold;
    margin: 0 0 20px;
    strike {
      display: block;
      font-size: 16px;
      color: #777;
      line-height: 1.5; }
    span {
      color: #D0021B; } }
  .color {
    margin: 0 0 15px;
    h3 {
      font-size: 16px;
      line-height: 1.5;
      margin: 0 0 10px; }
    span {
      display: inline-block;
      width: 30px;
      height: 30px;
      border-radius: 100%;
      margin-right: 5px;
      background-color: #eee;
      text-align: center;
      line-height: 30px;
      vertical-align: top;
      color: #fff;
      &.checked:before {
        content: "\f00c";
        font-family: fontAwesome; } } }

  .action-bar {
    margin: 0 0 30px;
    .action {
      @include mintablet {
        width: 50%;
        display: inline-block;
        vertical-align: top;
        margin: 0; }
      @include tablet {
        width: 100%; } }
    .btn-add-wishlist {
      @include minltablet {
        display: inline-block;
        vertical-align: top;
        line-height: 50px;
        margin-left: 10px; }
      @include maxtablet {
        display: block;
        margin: 10px 0 0;
        text-align: center; }
      &.saved {
        color: #777; } } }
  .genuine {
    position: relative;
    padding-left: 35px;
    margin: 0 0 20px;
    .icon {
      width: 20px;
      position: absolute;
      top: 0;
      left: 0; }
    p {
      padding-top: 3px; } }
  .location {
    position: relative;
    padding-left: 35px;
    .icon {
      width: 20px;
      position: absolute;
      top: 0;
      left: 0; }
    h3 {
      font-size: 16px;
      margin: 0 0 5px; }
    .btn-group.bootstrap-select.select {
      width: 210px !important;
      margin: 0 0 20px; }
    .list {
      p {
        margin: 0;
        position: relative;
        padding: 6px 0;
        padding-left: 30px;
        font-size: 14px;
        &:before {
          content: "\f00c";
          font-family: fontAwesome;
          color: #399023;
          position: absolute;
          top: 6px;
          left: 0; }
        &.unavailable {
          color: #B9B9B9;
          &:before {
            color: #B9B9B9; } } } } }

  .tabs {
    position: relative;
    .share {
      position: absolute;
      top: 0;
      right: 0;
      color: #777;
      font-size: 18px;
      line-height: 22px;
      @include mobile {
        position: static;
        line-height: 35px; } } }
  .nav-tabs {
    margin: 0 0 30px;
    li {
      a {
        @extend .font-size-18,.text-semibold;
        border: none;
        color: #777;
        padding: 0 50px 10px;
        &:hover,&:focus {
          background: none;
          color: #212121;
          border: none; } }
      &.active {
        a {
          color: #212121;
          background: none; } }
      &:first-child {
        a {
          padding-left: 0; } } }
    @include mobile {
      li {
        a {
          padding: 0 20px 10px; } } } }
  .tab-content {
    .tab-pane {
      padding-bottom: 100px;
      h3 {
        @extend .font-size-16,.text-semibold;
        margin: 0 0 15px; }
      ul {
        padding-left: 30px; }
      ul.spec {
        padding: 0;
        list-style: none;
        li {
          display: block;
          margin: 0 0 15px;
          b {
            float: left;
            width: 160px;
            @extend .text-semibold; }
          span {
            padding-left: 170px;
            display: block; } } }
      @include minltablet {
        width: 77%; }
      @include mintablet {
        ul.spec {
          column-count: 2;
          width: 91%; } }
      @include mobile {
        padding-bottom: 60px;
        ul.spec {
          li {
            b {
              width: 120px; }
            span {
              padding-left: 130px; } } } } } }
  .has-table,
  .cart {
    min-height: 700px;
    h2 {
      font-size: 21px;
      line-height: 25px;
      font-weight: 600; } }
  .shipping {
    margin-bottom: 40px;
    margin: 0 -15px;
    &:after {
      content: "";
      display: table;
      clear: both; }
    @include maxtablet {
      margin: 0 0 60px; }
    label {
      font-weight: 600;
      font-size: 16px; }
    .form-group {
      margin-bottom: 15px; }
    .form-control {
      height: 50px;
      border-radius: 2px;
      &+.form-control {
        margin-top: 10px; } }
    .bootstrap-select {
      &.select {
        display: block;
        .btn {
          height: 48px; }
        .caret {
          right: 25px !important;
          @include mobile {
            top: 14% !important; } } } }
    h3 {
      font-size: 18px;
      line-height: 25px;
      font-weight: 600 !important; }
    .diferent-adr {
      background: #f9f9f9;
      margin: 30px -15px 0;
      padding: 20px 15px;
      display: none; }
    .form-ship {
      margin: 30px 0 0;
      display: none;
      &.active {
        display: block; }
      .ship-cost {
        border: dotted #e2e2e2;
        border-width: 1px 0;
        padding: 10px 0;
        b {
          float: right;
          font-weight: 600; }
        .info {
          padding: 10px;
          border: 1px solid #ddd;
          background: #f9f9f9;
          border-radius: 4px; } } }
    .form-action {
      a {
        display: block;
        text-align: center;
        margin: 10px 0; } } }


  .cart-action {
    @include mintablet {
      float: right;
      width: 20%; }
    .btn {
      display: block; }
    .coupon {
      width: 100%;
      text-align: center;
      display: block;
      margin-top: 10px; } }
  .cart-sidebar {
    background-color: #f5f5f5;
    padding: 20px;
    width: 100%;
    margin-top: 45px;
    .btn {
      width: 100%;
      line-height: 50px;
      font-size: 18px;
      font-weight: 600; }
    .coupon {
      width: 100%;
      text-align: center;
      display: block;
      margin-top: 10px; }
    .subtotal {
      padding: 20px 0 5px;
      border-bottom: 1px solid #e2e2e2;
      line-height: 19px;
      li {
        list-style: none;
        margin-bottom: 15px;
        span {
          float: right; } } }
    .total {
      @extend .text-bold;
      padding: 20px 0;
      margin-bottom: 40px;
      line-height: 19px;
      span {
        float: right; } }
    &.with-save {
      .total {
        margin-bottom: 10px;
        padding-bottom: 5px; }
      .save {
        margin-bottom: 13px;
        line-height: 19px;
        span {
          float: right; } }
      .total-save {
        @extend .text-bold;
        text-transform: uppercase;
        background: #c0392b;
        margin-bottom: 40px;
        color: #fff;
        padding: 7px;
        span {
          float: right; } } } }

  &.checkout {
    padding-top: 40px;
    h1 {
      font-size: 36px;
      line-height: 43px;
      margin-bottom: 10px;
      font-weight: 600;
      text-align: center; }
    .subtitle {
      font-size: 21px;
      line-height: 25px;
      text-align: center;
      font-weight: 600; }
    .checkout-box {
      padding: 40px 30px;
      margin-top: 60px;
      box-shadow: 0 10px 15px 0 rgba(0,0,0,0.2);
      border: 2px solid #ddd;
      border-bottom: 0;
      text-align: left;
      position: relative;
      &:before,
      &:after {
        content: "";
        display: block;
        position: absolute;
        width: 30px;
        height: 100%;
        background: url(../images/dot-border.jpg);
        top: 0;
        left: 0; }
      &:after {
        left: auto;
        right: 0; }
      .logo {
        margin-left: 45px;
        @include mobile {
          margin: 0 auto 30px;
          display: block; } }
      .info {
        width: 40%;
        float: right;
        padding: 0 15px;
        @include tablet {
          width: 55%; }
        @include mobile {
          width: 100%; } }
      .table {
        margin-bottom: 0; }
      .box-total {
        width: 28%;
        float: right;
        margin-bottom: 20px;
        margin-right: 82px;
        @include tablet {
          margin-right: 23px;
          width: 45%; }
        @include mobile {
          width: 100%;
          margin-right: 0; } }
      .subtotal {
        padding: 20px 0 5px;
        border-bottom: 1px solid #e2e2e2;
        line-height: 19px;
        li {
          list-style: none;
          margin-bottom: 15px;
          span {
            float: right; } } }
      .total {
        @extend .text-bold;
        padding: 20px 0;
        margin-bottom: 40px;
        line-height: 19px;
        span {
          float: right; } } } }
  &.static {
    article {
      &.pb-extra {
        margin-bottom: 170px; }
      ul,ol {
        margin-bottom: 30px;
        padding-left: 30px; }
      h1 {
        font-size: 36px;
        line-height: 43px;
        margin-bottom: 40px;
        font-weight: 600;
        text-align: center; }
      p {
        text-align: justify;
        &.first {
          font-size: 21px;
          line-height: 30px;
          text-align: center;
          margin-bottom: 60px; } }
      .btn {
        width: 200px;
        text-align: center;
        line-height: 60px;
        display: block;
        margin: auto; }
      .box-white {
        margin: 20px 0 80px;
        position: relative;
        @include tablet {
          margin: 0 0 60px; }
        @include mobile {
          margin: 0 0 15px; }
        .img {
          width: 60%;
          height: 400px;
          background-color: #D8D8D8;
          position: relative;
          background-size: cover;
          background-position: center center;
          @include tablet {
            height: 330px; }
          @include mobile {
            width: 100%;
            height: 300px; } }
        .content {
          display: block;
          width: 50%;
          padding: 30px 25px;
          background-color: #fff;
          position: absolute;
          right: 0%;
          top: 35%;
          text-align: left;
          @include tablet {
            top: 0;
            width: 40%;
            padding: 15px; }
          @include mobile {
            position: relative;
            left: 0;
            width: 100%;
            padding: 15px 0; } } } }
    .contact {
      h1 {
        font-size: 36px;
        line-height: 43px;
        margin-bottom: 20px;
        font-weight: 600;
        text-align: center; }
      p {
        text-align: center; } }
    .box-contact {
      margin: 80px 0 360px;
      @include tablet {
        margin: 60px 0 200px; }
      @include mobile {
        margin: 60px 0; }
      .item-contact {
        border: 1px solid #979797;
        border-radius: 4px;
        padding: 20px;
        text-align: center;
        display: block;
        width: calc(25% - 20px);
        height: 240px;
        margin: 0 10px;
        float: left;
        @include tablet {
          width: calc(50% - 20px);
          margin-bottom: 20px; }
        @include mobile {
          width: calc(100% - 20px);
          margin-bottom: 20px; }
        img {
          width: 48px;
          height: 48px;
          margin: 0 auto;
          background-color: #ddd;
          margin-bottom: 20px; }
        .title {
          font-weight: 600;
          margin-bottom: 5px; }
        .tel {
          font-size: 26px;
          line-height: 31px;
          display: block; }
        .btn {
          line-height: 30px; }
        a {
          font-weight: 600;
          line-height: 30px; }
        p {
          margin-top: 20px;
          padding: 0 10px; } } }
    .signup-email {
      h1 {
        font-size: 36px;
        line-height: 43px;
        margin-bottom: 5px;
        font-weight: 600;
        text-align: center; }
      p {
        font-size: 21px;
        line-height: 25px;
        text-align: center;
        font-weight: 600; }
      form {
        padding: 0 30px;
        margin: 80px 0 500px;
        .alert {
          &:before {
            margin-right: 10px; } }
        .form-control {
          height: 50px;
          margin-right: 15px;
          @include mintablet {
            width: calc(100% - 140px); } }
        .btn {
          line-height: 48px;
          padding: 0 27px;
          font-size: 18px;
          @include mobile {
            margin: 20px 0 0;
            width: 100%;
            text-align: center; } } } } }
  &.news {
    .socmed {
      position: absolute;
      padding-left: 15px;
      @include mobile {
        position: relative;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        padding-left: 0;
        padding-bottom: 30px; }
      a {
        background-color: #D8D8D8;
        width: 50px;
        line-height: 50px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 25px;
        margin-top: 20px;
        color: #777;
        font-size: 21px;
        &:hover {
          color: $orange; }
        @include mobile {
          margin: 20px 10px; } } }
    h2 {
      text-align: center;
      font-size: 36px;
      line-height: 43px;
      font-weight: 600;
      margin: 40px 15px 60px;
      span {
        background-color: #fff;
        padding: 0 40px; }
      &:before {
        content: "";
        display: block;
        width: 100%;
        height: 3px;
        background-color: #e2e2e2;
        position: absolute;
        top: calc(50% - 2px);
        z-index: -1; } }
    article {
      p {
        text-align: justify;
        &.first {
          font-size: 21px;
          line-height: 30px;
          text-align: center;
          margin-bottom: 60px; } }
      &.pb-extra {
        margin-bottom: 170px;
        @include mobile {
          margin-bottom: 30px; } } }
    .col-sm-4 {
      @include mintablet {
        &:nth-child(3n+1) {
          clear: left; } } }
    .item-news {
      margin-bottom: 60px;
      .img {
        float: none;
        width: 100%;
        display: block;
        height: 360px;
        background-color: #D8D8D8;
        overflow: hidden;
        align-items: center;
        display: -ms-flexbox;
        display: flex;
        display: -webkit-flex;
        -webkit-align-items: center;
        -webkit-box-align: center;
        -webkit-box-pack: center;
        box-align: center;
        @include tablet {
          height: 220px; }
        @include mobile {
          height: 300px; }
        img {
          transition: transform .3s;
          transform: scale(1); } }
      .title {
        display: block;
        font-size: 21px;
        line-height: 30px;
        margin: 20px 0; }
      p {
        color: #777; }
      &:hover {
        .img {
          img {
            transform: scale(1.05); } } }
      &.featured {
        text-align: center;
        .img {
          height: 600px;
          @include tablet {
            height: 400px; }
          @include mobile {
            height: 300px; } }
        .title {
          font-size: 36px;
          line-height: 48px;
          padding: 0 14%;
          margin-top: 60px;
          @include mobile {
            font-size: 26px;
            line-height: 34px;
            margin-top: 30px; } } } }
    .lmore {
      margin-bottom: 40px;
      padding-top: 40px; } } }

.related {
  @extend .pb120; }



@include keyframes(rotate) {
  0% {
    transform: rotate(0deg); }
  100% {
    transform: rotate(360deg); } }


.not-found {
  overflow: hidden;
  padding: 0;
  .fs {
    padding: 0;
    overflow: hidden;
    color: $orange;
    @include center;
    text-align: center;
    width: 100wh;
    height: 100vh; }
  h1 {
    font-size: 120px;
    @include mintablet {
      font-size: 311px; }
    line-height: 0.8;
    @extend .text-bold; }
  p {
    @extend .font-size-26,.text-light;
    color: #212121; }
  .btn {
    @extend .text-capital,.text-bold;
    font-size: 18px;
    position: relative;
    z-index: 10;
    line-height: 60px;
    padding: 0 20px; } }



.account {
  margin-top: -200px;
  @include mintablet {
    min-height: 700px; }
  @include tablet {
    margin-top: -65px;
    height: 700px !important; }
  @include mobile {
    margin-top: -65px;
    height: auto !important; }
  .bottom-link {
    position: absolute;
    bottom: 30px;
    left: 0;
    width: 100%;
    text-align: center;
    @media screen and (min-width: 1400px) {
      bottom: 80px; }
    a {
      color: #777;
      &:hover {
        color: $orange; }
      @include maxtablet {
        display: block; } } }
  .image {
    width: 68%;
    height: 100%;
    background-color: #D8D8D8;
    background-size: cover;
    background-position: center;
    background-image: url('../images/hero-login.jpg');
    float: left;
    @include tablet {
      width: 55%; }
    @include mobile {
      width: 100%;
      height: 250px;
      float: none; } }
  .sliders {
    width: 68%;
    height: 100%;
    float: left;
    position: relative;
    background: $orange;
    .owl-item,.owl-stage-outer,.owl-stage {
      height: 100%; }
    .image,
    .video-wrap {
      width: 100%;
      float: none; }
    .owl-dots {
      position: absolute;
      bottom: 20px;
      right: 20px;
      .owl-dot {
        height: 7px;
        width: 40px;
        background: #fff;
        border: none;
        &.active {
          background: $orange; } } }
    @include tablet {
      width: 55%; }
    @include mobile {
      width: 100%;
      height: 250px;
      float: none; } }
  .video-wrap {
    width: 68%;
    height: 100%;
    float: left;
    position: relative;
    background: #000;
    display: flex;
    align-items: center;
    iframe {
      height: 100%;
      width: 100%;
      border: 0; }
    @include tablet {
      width: 55%; }
    @include mobile {
      width: 100%;
      height: 250px;
      float: none; } }

  .box-form {
    width: 32%;
    height: 100%;
    min-height: 700px;
    float: left;
    padding: 50px;
    display: flex;
    align-items: center;
    position: relative;
    .msg {
      padding: 10px 15px;
      background: #eee;
      margin: 0 0 15px;
      border: 1px solid #ddd;
      border-radius: 5px; }
    @media screen and (min-width: 1400px) {
      padding: 80px; }
    @include tablet {
      padding: 30px;
      width: 45%; }
    @include mobile {
      width: 100%;
      float: none;
      padding: 30px 15px; }
    .content {
      width: 100%;
      @include mobile {
        padding-bottom: 100px; } }
    .logo {
      margin-bottom: 50px;
      @include maxtablet {
        text-align: center; } }
    h2 {
      font-size: 26px;
      font-weight: 600;
      display: block;
      margin-bottom: 30px;
      @include maxtablet {
        font-size: 21px; } }
    label {
      font-size: 16px;
      font-weight: 600; }
    .form-control {
      width: 100%;
      height: 50px;
      border-radius: 2px; }
    .forgot {
      line-height: 50px;
      color: #777; }
    button[type=submit] {
      float: right;
      height: 50px;
      width: 150px;
      @include maxtablet {
        width: 100%; } } }
  &.sign-up {
    height: 1050px !important;
    button[type=submit] {
      width: auto;
      @include maxtablet {
        width: 100%; } }
    @include tablet {
      height: 900px !important; }
    @include mobile {
      height: 1100px !important;
      .box-form {
        height: auto; } } } }

.locations {
  .row > b {
    padding: 0 15px; }
  .hero {
    @include backgroundSize(cover);
    background-position: center;
    background-color: #D8D8D8;
    padding: 30% 0;
    margin: 0 0 30px;
    position: relative; }
  .map {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%; }
  h2 {
    font-size: 21px;
    line-height: 1;
    font-weight: 600;
    margin: 0 0 20px; }
  form {
    padding: 0 0 30px;
    border-bottom: 1px solid #e2e2e2;
    input[type="text"] {
      padding: 17px 15px;
      height: 50px;
      display: inline-block;
      @include mintablet {
        width: 550px; } }
    button.btn {
      line-height: 48px;
      padding: 0 30px;
      margin-left: 20px;
      @include mobile {
        margin: 0;
        margin-top: 20px; } } }
  .content {
    min-height: 189px;
    padding: 30px 0;
    h2 {
      margin: 0 0 30px; }
    .item {
      margin: 0 0 60px;
      h3 {
        font-size: 18px;
        line-height: 1;
        font-weight: 600;
        margin: 0 0 10px; } }
    .row {
      .item {
        @include mintablet {
          &:nth-child(3n+1) {
            clear: left; } }
        @include mobile {
          &:nth-child(2n+1) {
            clear: left; } } } } }
  .empty {
    @extend .ptd80;
    text-align: center;
    .fa {
      font-size: 100px;
      display: block;
      color: $orange; } } }



.alert.alert-success {
  background: #73B552;
  border-radius: 4px;
  color: #fff;
  &:before {
    content: "\f058";
    font-family: fontAwesome; }
  .close {
    position: relative;
    top: 3px; } }

div.notifyjs-bootstrap-success {
  background: #73B552;
  border-radius: 0 0 4px 4px;
  color: #fff;
  padding: 20px 50px;
  span {
    color: #fff;
    font-size: 16px;
    line-height: 1;
    @extend .text-semibold; } }
div.notifyjs-corner {
  margin: 0;
  margin-left: -16%;
  @include tablet {
    margin-left: -35%; }
  @include mobile {
    left: 0 !important;
    width: 100%;
    overflow: hidden;
    margin: 0;
    .notifyjs-wrapper {
      margin: 0;
      .notifyjs-container {
        margin: 0;
        .notifyjs-bootstrap-base {
          word-wrap: break-word;
          white-space: normal;
          padding: 15px 10px;
          text-align: center; } } } } }

.bx-wrapper {
  @include mintablet {
    margin: 0; }
  .bx-viewport {
    box-shadow: none; } }

.ui-autocomplete {
  box-shadow: 0 2px 4px 0 rgba(0,0,0,0.5);
  border-radius: 2px;
  margin: 5px 0 0;
  .ui-menu-item {
    .list-place {
      display: block;
      font-size: 16px;
      line-height: 1;
      padding: 12px 15px;
      b {
        font-weight: 600; }
      .fa {
        margin-right: 15px; }
      &.ui-state-active {
        background: #f9f9f9;
        color: #212121;
        border: none;
        margin: 0; } } } }
.wishlist {
  padding-bottom: 385px;
  @include mobile {
    padding-bottom: 100px; } }

.my-profile {
  padding-bottom: 100px;
  h2 {
    font-size: 21px;
    line-height: 25px;
    font-weight: 600;
    margin: 0 0 30px; }
  form {
    max-width: 440px;
    .form-control {
      height: 50px;
      &+ .form-control {
        margin-top: 10px; } }
    label {
      font-size: 16px;
      line-height: 19px;
      font-weight: 600;
      margin: 0 0 10px; }
    .form-group {
      a {
        color: #777;
        text-decoration: underline;
        line-height: 19px;
        margin-top: 25px;
        display: inline-block; } }
    .new-pass {
      display: none; }
    .shipping-form {
      margin-top: 60px; }
    .action-form {
      text-align: right;
      .btn-black {
        line-height: 50px;
        padding: 0 17px; } } } }

.back-to-top {
  position: fixed;
  bottom: 70px;
  right: 15px;
  text-align: center;
  z-index: 100;
  cursor: pointer;
  opacity: 1;
  visibility: visible;
  @include transition(all .5s);
  .fa {
    font-size: 30px;
    line-height: 35px;
    height: 40px;
    width: 40px;
    text-align: center;
    color: #fff;
    border-radius: 100%;
    display: inline-block;
    @include background('../images/puff.svg',cover,center);
    margin: 0 0 5px; }
  &.h {
    bottom: 0;
    opacity: 0;
    visibility: hidden; } }
.box-map {
  padding-bottom: 30px; }
.ballon-box {
  cursor: text;
  text-align: center;
  font-weight: 400!important;
  margin: -10px;
  .baloon {
    text-align: left;
    transition: all .5s;
    //background: #fff
    padding: 15px;
    position: relative;

    @include minltablet {
      min-width: 300px; }
 }    //top: -15px
  .photo,p {
    margin: 0 0 20px; }
  .photo {
    @include backgroundSize(cover);
    background-position: center;
    background-color: #f9f9f9;
    &:before {
      content: "";
      padding: 30% 0;
      display: block; } }
  .cd {
    margin: 0 0 10px;
    a {
      font-size: 16px;
      line-height: 24px;
      display: block;
      .fa {
        position: relative;
        top: 1px; } } } }

.promo {
  position: relative;

  .info-promo {
    position: absolute;
    top: 100%;
    left: -30%;
    right: -30%;
    background: #f9f9f9;
    border: 1px solid #e2e2e2;
    padding: 10px;
    dl {
      &:after {
        content: "";
        display: table;
        clear: both; } } } }


.banner {
  padding-bottom: 100px;
  img {
    margin-bottom: 30px; }
  .video-box {
    margin-bottom: 30px;
    position: relative;
    &:after {
      content: "";
      padding: 28% 0;
      display: block; }
    iframe {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%; } }
  .slider {
    a {
      margin: 0 15px;
      display: block; } }

  .owl-dots {
    margin-top: 15px;
    .owl-dot {
      &.active {
        background: $orange; } } } }


.share {
  a {
    display: inline-block;
    height: 26px;
    width: 26px;
    text-align: center;
    position: relative;
    transition: transform .3s;
    transform: translateY(0);
    .fa {
      vertical-align: middle;
      position: relative;
      top: 2px;
      color: #fff; }
    &.tumblr {
      background-color: #3d4c61; }
    &.facebook {
      background-color: #4362a3; }
    &.twitter {
      background-color: #2eb0fc; }
    &.plus {
      background-color: #f95a4c; }
    &:hover {
      transform: translateY(-2px); } } }


.admin {
  .account {
    display: flex;
    justify-content: center; }
  .box-form {
    .logo,
    h2 {
      text-align: center; } }
  footer {
    text-align: center;
    padding: 40px 0 30px; } }

.zoomPad {
  @include mobile {
    float: none; } }

.jumbotron {
  background: #f9f9f9;
  padding: 30px 0;

  h1 {
    font-size: 24px;
    &:before {
      content: "\f07a";
      font-family: fontAwesome;
      display: block;
      font-size: 50px;
      color: #bbb;
      margin: 0 0 10px; } }
  .action {
    display: inline-block;
    .btn.btn-transparent {
      display: inline-block; } }
  &.wish {
    h1:before {
      content: "\f004"; } } }


.confirm {
  .modal-dialog {
    max-width: 400px;
    .modal-content {
      padding: 30px;
      text-align: center; } } }

iframe#drift-widget {
  @include responsive(0,1200px);
  right: 0 !important;
  bottom: 0 !important; }
