/* ------------------------------------------------------------------------------------------------ */
/* Modal */
/* ------------------------------------------------------------------------------------------------ */

.modal-backdrop {
  background-color: #e2e2e2;
  opacity: 0.5 !important; }
.modal {
  &.invoice {
    .modal-dialog {
      width: 930px;
      @include tablet {
        width: 700px; }
      @include mobile {
        width: calc(100% - 20px); }
      .modal-content {
        border-radius: 0;
        box-shadow: 0 10px 15px 0 rgba(0,0,0,0.2);
        border: 2px solid #b9b9b9;
        position: relative;
        &:before,
        &:after {
          content: "";
          display: block;
          position: absolute;
          width: 30px;
          height: 100%;
          background: url(../images/dot-border.jpg);
          top: 0;
          left: 0;
          @include mobile {
            width: 15px;
            background-size: 100% auto; } }
        &:after {
          left: auto;
          right: 0; }
        .close {
          position: absolute;
          top: 0;
          right: 15px;
          width: 35px;
          line-height: 35px;
          text-align: center;
          background: #aaa;
          color: #fff;
          opacity: 1;
          z-index: 2; }
        .modal-body {
          padding: 40px 30px 29px;
          @include mobile {
            padding: 40px 15px 30px; }
          .logo {
            margin-left: 45px;
            @include mobile {
              margin: 0 auto 30px;
              display: block;
              max-width: 80%; } }
          .info {
            width: 40%;
            float: right;
            padding: 0 15px;
            @include tablet {
              width: 55%; }
            @include mobile {
              width: 100%; } }
          .table {
            margin-bottom: 0;
            tbody {
              tr {
                th:nth-child(2) {
                  width: 153px; } } } }
          .box-total {
            width: 23%;
            float: right;
            margin-bottom: 20px;
            margin-right: 82px;
            @include tablet {
              margin-right: 23px;
              width: 45%; }
            @include mobile {
              width: 100%;
              margin-right: 0; } }
          .subtotal {
            padding: 20px 0 5px;
            border-bottom: 1px solid #e2e2e2;
            line-height: 19px;
            li {
              list-style: none;
              margin-bottom: 15px;
              span {
                float: right; } }
            @include mobile {
              padding: 10px;
              li {
                border-bottom: 1px dotted #e2e2e2; } } }
          .total {
            @extend .text-bold;
            padding: 20px 0;
            margin-bottom: 40px;
            line-height: 19px;
            span {
              float: right; }
            @include mobile {
              background: #f9f9f9;
              padding: 20px 10px;
              border: 1px solid #e2e2e2; } } } } } } }

.popup {
  .modal-content {
    position: relative;
    .close {
      position: absolute;
      top: -20px;
      right: -20px;
      width: 40px;
      line-height: 40px;
      border-radius: 100%;
      text-align: center;
      background: $orange;
      color: #fff;
      opacity: 1;
      @include mobile {
        top: 0;
        right: 0; } } }

  .text {
    padding: 20px 40px;
    @include mobile {
      padding: 20px 10px; }
    p {
      margin: 0 0 15px; }
    ul {
      padding: 0;
      margin: 0; }
    ol {
      padding-left: 20px; }
    li {
      margin: 0 0 10px; } } }
.modal-backdrop.in.home {
  background: rgba(#000,.5); }

.promo-modal {
  .modal-content {
    position: relative;
    padding: 30px;
    border-radius: 10px;
    @include mobile {
      padding: 30px 15px; } }
  h2 {
    font-size: 30px;
    line-height: 1;
    padding: 10px;
    margin: -30px -30px 0;
    background: #f9f9f9;
    @include mobile {
      margin: -30px -15px 0; } }
  h3 {
    font-size: 18px;
    line-height: 30px;
    text-align: center;
    color: $orange; }
  .modal-dialog {
    @include mintablet {
      max-width: 400px; }
    .close {
      position: absolute;
      top: 0;
      right: -40px;
      width: 40px;
      line-height: 40px;
      border-radius: 100%;
      text-align: center;
      color: #fff;
      opacity: 1;
      @include mobile {
        top: 0;
        right: 0;
        z-index: 1;
        color: #999; } } }
  .table-calculate {
    th {
      background: none;
      font-weight: 400; }
    th,td {
      padding: 5px;
      border: none;
      border-bottom: 1px dotted #e2e2e2;
      font-size: 16px; }
    td {
      text-align: right;
      min-width: 150px; }
    tr.total {
      td,th {
        border: solid #e2e2e2;
        border-width: 1px 0;
        background: #f9f9f9;
        font-weight: 600;
        padding: 10px 5px; } } } }
