/* ------------------------------------------------------------------------------------------------ */
/* Form */
/* ------------------------------------------------------------------------------------------------ */

.form-group {
  margin: 0 0 20px;
  label {
    @extend .font-size-16;
    color: #212121;
    input[type="checkbox"] {
      float: left;
      &+ p {
        padding-left: 36px;
        margin: 0; } }
    &.error {
      background: #f03f54;
      font-style: italic;
      text-align: right;
      display: block;
      font-size: 12px;
      padding: 0 5px;
      border-radius: 0 0 3px 3px;
      color: #fff; } }
  &.has-error {
    label {
      color: #a94442; }
    .form-control {
      border: 1px solid #ebccd1; }
    .help-block {
      display: block;
      background: #f2dede;
      border: 1px solid #ebccd1;
      color: #a94442;
      border-radius: 2px;
      padding: 5px 10px;
      &:before {
        content: "\f06a";
        font-family: fontAwesome;
        color: darken(#a94442,10%); } } } }
.form-control {
  border: 1px solid #ccc;
  height: 40px;
  box-shadow: none;
  color: #000;
  font-size: 16px;
  &.error {
    border-color: #cc3333;
    border-width: 2px; } }
.alert {
  img {
    width: 45px;
    margin-right: 10px; } }

.form-group {
  label {
    font-size: 14px; } }

.bootstrap-select.select {
  @extend .form-control;
  color: #999;
  background: #fff;
  width: 100% !important;
  height: auto;
  border-radius: 4px;
  height: 50px;
  &.open {
    overflow: visible; }
  .btn {
    height: 48px;
    background: #fff;
    color: #555;
    border-radius: 4px;
    padding-left: 15px;
    padding-right: 42px;
    line-height: 36px;
    @extend .text-regular;
    span {
      padding: 0; }
    &:after {
      display: none; } }
  .caret {
    border: none;
    top: 14% !important;
    right: 35px !important;
    &:before {
      font-family : "FontAwesome";
      content: "\f107";
      width: 10px;
      height: 10px;
      font-size: 21px; }
    @media(max-width: 767px) {
      top: 6% !important; } }
  .dropdown-menu {
    &.open {
      overflow: visible; }
    .selected {
      background: #fafafa; }
    a {
      outline: none;
      padding: 10px 15px; }
    li {
      a {
        display: block;
        margin: 0; }
      &.active a {
        color: #fff; } } } }
.btn-group.open .dropdown-toggle {
  box-shadow: none; }

.col-4 {
  .form-control {
    @media(min-width: 768px) {
      width: 23%;
      margin-right: 1%;
      display: inline-block;
      &:last-child {
        margin: 0;
        width: 26%; } }
    @media(max-width: 992px) and (min-width: 769px) {
      &:last-child {
        width: 25%; } }
    @media(max-width: 768px) {
      margin: 0 0 10px; } } }
select.form-control {
  position: relative;
  &:after {
    position: absolute;
    font: 20px/45px FontAwesome;
    content: "\f107";
    top: 0;
    right: 5px; }
  @media(min-width: 768px) {
    display: inline-block;
    width: 30%; } }

.date-input {
  position: relative;
  display: block;
  input {
    margin: 0 !important; }
  &:after {
    content: "\f073";
    font-family: FontAwesome;
    position: absolute;
    right: 11px;
    top: 8px;
    color: #999;
    font-size: 24px; } }

input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0; }

input[type=number] {
  -moz-appearance: textfield; }

.table {
  margin-bottom: 60px;
  tbody,thead,tfoot {
    tr {
      th {
        background-color: #eee;
        border: 0;
        font-size: 18px;
        line-height: 18px;
        font-weight: 600;
        padding: 16px 20px;
        &.delete {
          width: 55px;
          text-align: right; } }
      td {
        border-top: 0;
        font-size: 16px;
        padding: 14px 20px;
        border-bottom: 1px solid #e2e2e2;
        vertical-align: middle;
        .disc {
          min-width: 32px;
          display: block;
          text-align: left;
          vertical-align: middle;
 }          // text-align: right
        .ms {
          display: block;
          font-size: 14px;
          text-align: left;
          margin-right: 16px; }
        img {
          height: 35px;
          display: inline-block;
          margin-right: 15px; }
        a {
          text-decoration: underline; }
        .qty {
          height: 40px;
          width: 50px;
          text-align: center;
          margin: -9px 0;
          border-radius: 2px;
          padding: 0 5px; }
        .qty-box {
          position: relative;
          padding-right: 30px;
          width: 80px;
          border: 1px solid #ccc;
          span {
            position: absolute;
            right: 0;
            top: 0;
            width: 30px;
            line-height: 20px;
            border: 1px solid #ccc;
            font-size: 18px;
            font-weight: 600;
            text-align: center;
            margin: -1px;
            cursor: pointer;
            &:hover {
              background: #f9f9f9; }
            &.minus {
              margin-top: 0;
              border-top: none;
              top: 50%; } }
          input.qty {
            width: 100%;
            border: none;
            margin: 0; } }
        .fa-times-circle {
          color: #777;
          &:hover {
            color: $orange; } }
        .link-promo {
          margin-left: 8px;
          float: right; } } } }
  tfoot {
    tr {
      td {
        border: none; }
      &.order-total {
        font-weight: 600; } } } }
.ot-table {
  tfoot {
    tr.coupon {
      td {
        background: #f9f9f9;
        input.form-control {
          display: inline-block;
          width: 150px; } } } } }
@include mobile {
  .ot-table {
    border: 1px solid #e2e2e2;
    thead {
      th,tr {
        display: none; } }
    tbody {
      tr {
        &:not(:last-child) {
          border-bottom: 3px solid #aaa; }
        display: block;
        td {
          padding: 10px;
          display: block;
          position: relative;
          padding-left: 100px;
          border: none;
          &:before {
            content: attr(data-title);
            background: #eee;
            font-weight: 600;
            position: absolute;
            left: 0;
            top: 0;
            bottom: 0;
            padding: 10px;
            width: 90px; }
          &:not(:last-child) {
            border-bottom: 1px solid #ccc; } } } }
    tfoot {
      tr {
        padding: 0 10px;
        display: block;
        background: #ddd;
        position: relative;
        &.order-total {
          border-top: 5px solid #aaa; }
        td {
          display: block;
          padding: 10px 0;
          &:not(.text) {
            padding-left: 100px; }
          &.text {
            position: absolute;
            top: 0;
            left: 10px; } }
        &.coupon {
          background: #ddd;
          td {
            background: none;
            padding: 10px;
            input.form-control {
              width: 100%; } } } } } } }


.checkbox {
  margin-top: 20px;
  line-height: 0;
  font-size: 0;
  label {
    padding-left: 35px;
    position: relative;
    margin: 0;
    font-size: 16px;
    line-height: 20px;
    font-weight: normal !important; }
  input[type="checkbox"] {
    -webkit-appearance: none;
    -moz-appearance: none;
    position: absolute;
    top: 0;
    left: 0;
    padding: 0;
    margin: 0;
    width: 20px;
    height: 20px;
    background-color: #fff;
    border-radius: 2px;
    cursor: pointer;
    color: #fff;
    border: 1px solid #e2e2e2;
    &:focus {
      outline: none; }
    &:checked {
      border: 0;
      &:after {
        content: "\f00c";
        font-family: FontAwesome;
        font-size: 14px;
        position: absolute;
        width: 100%;
        height: 100%;
        background-color: $orange;
        border-radius: 2px;
        padding: 3px; } } }
  input[type=checkbox][disabled] {
    background-color: #E2E2E2; }
  &.dis {
    label {
      color: #B9B9B9; } } }

dl {
  margin-bottom: 40px;
  dt {
    font-weight: normal;
    position: absolute; }
  dd {
    padding-left: 120px;
    margin-bottom: 20px; } }

.list-ship {
  margin: 10px 0;
  label {
    display: block;
    position: relative;
    cursor: pointer;
    .box {
      padding: 15px;
      border: 1px solid #e2e2e2;
      border-radius: 5px; }
    .img {
      position: relative;
      background: #f9f9f9;
      &:before {
        content: "";
        display: block;
        padding: 50% 0; }
      b {
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        @include center;
        img {
          display: block;
          margin: auto;
          max-width: 100%; } } }
    h3 {
      margin: 10px 0 0;
      text-align: center; }
    input {
      position: absolute;
      top: 0;
      left: 0;
      width: 0;
      visibility: hidden;
      &:checked + .box {
        background: $orange;
        color: #fff;
        box-shadow: 0px 3px 10px 0px rgba(0,0,0,0.3); } } } }

.form-group.color {
  label {
    display: inline-block;
    margin: 0;
    position: relative;
    vertical-align: top;
    div {
      width: 30px;
      height: 30px;
      border-radius: 100%;
      background: #000;
      margin-right: 5px;
      text-align: center;
      line-height: 30px;
      vertical-align: top;
      color: #fff;
      cursor: pointer; }
    input {
      position: absolute;
      top: 0;
      left: 0;
      width: 0;
      visibility: hidden;
      &:checked + div {
        &:before {
          content: "\f00c";
          font-family: fontAwesome; } } } } }
