@mixin font-face($name, $font-files, $eot: false, $weight: false, $style: false) {
  $iefont: unquote("#{$eot}?#iefix");
  @font-face {
    font-family: quote($name);
    @if $eot {
      src: font-url($eot);
      $font-files: font-url($iefont) unquote("format('embedded-opentype')"), $font-files; }
    src: $font-files;
    @if $weight {
      font-weight: $weight; }
    @if $style {
      font-style: $style; } } }

@function calculateRem($size) {
  $remSize: $size / 16px;
  @return $remSize * 1rem; }

@mixin font-size($size) {
  font-size: $size;
  font-size: calculateRem($size); }

@mixin bp-large {
  @media only screen and (max-width: 60em) {
    @content; } }

@mixin bp-medium {
  @media only screen and (max-width: 40em) {
    @content; } }

@mixin bp-small {
  @media only screen and (max-width: 30em) {
    @content; } }

@mixin keyframes($animation-name) {
  @-webkit-keyframes #{$animation-name} {
    @content; }

  @-moz-keyframes #{$animation-name} {
    @content; }

  @-ms-keyframes #{$animation-name} {
    @content; }

  @-o-keyframes #{$animation-name} {
    @content; }

  @keyframes #{$animation-name} {
    @content; } }

@mixin animation($str) {
  -webkit-animation: #{$str};
  -moz-animation: #{$str};
  -ms-animation: #{$str};
  -o-animation: #{$str};
  animation: #{$str}; }

@mixin transition($args...) {
  -webkit-transition: $args;
  -moz-transition: $args;
  -ms-transition: $args;
  -o-transition: $args;
  transition: $args; }

@mixin opacity($opacity) {
  opacity: $opacity;
  $opacity-ie: $opacity * 100;
  filter: alpha(opacity = $opacity-ie); }

@mixin transform($translate3d) {
  transform: $translate3d;
  -webkit-transform: $translate3d;
  -moz-transform: $translate3d;
  -o-transform: $translate3d;
  -ms-transform: $translate3d; }

@mixin background($path,$size: auto,$position:0 0,$repeat: no-repeat) {
  background: transparent url(#{$path}) $repeat $position;
  background-size: $size;
  -webkit-background-size: $size;
  -moz-background-size: $size; }
@mixin backgroundSize($val) {
  background-size: $val;
  -webkit-background-size: $val;
  -moz-background-size: $val; }
@mixin filter($value) {
  -webkit-filter : $value;
  filter: $value; }
@mixin center() {
  align-items: center;
  display: flex;
  display: -webkit-flex;
  -webkit-align-items: center;
  -webkit-box-align: center;
  -webkit-box-pack: center;
  box-align: center; }

@mixin responsive($max:0,$min: 0) {
  @if $min == 0 {
    @media(max-width: $max) {
      @content; } }
  @else if $max == 0 {
    @media(min-width: $min) {
      @content; } }
  @else {
    @media(max-width: $max) and  (min-width: $min) {
      @content; } } }
@mixin mobile() {
  @include responsive(767px) {
    @content; } }
@mixin maxtablet() {
  @include responsive(991px) {
    @content; } }
@mixin mintablet() {
  @include responsive(0,768px) {
    @content; } }
@mixin minltablet() {
  @include responsive(0,992px) {
    @content; } }
@mixin tablet() {
  @include responsive(992px,768px) {
    @content; } }
@mixin ltablet() {
  @include responsive(1200px,992px) {
    @content; } }
@mixin sdekstop() {
  @include responsive(1200px,1024px) {
    @content; } }
@mixin large() {
  @include responsive(0,1200px) {
    @content; } }

//IE8

%clearfix {
  *zoom: 1;
  &:before, &:after {
    content: " ";
    display: table; }
  &:after {
    clear: both; } }

%visuallyhidden {
  margin: -1px;
  padding: 0;
  width: 1px;
  height: 1px;
  overflow: hidden;
  clip: rect(0 0 0 0);
  clip: rect(0, 0, 0, 0);
  position: absolute; }
