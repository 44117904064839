/* ------------------------------------------------------------------------------------------------ */
/* Header */
/* ------------------------------------------------------------------------------------------------ */


header {
  position: absolute;
  left: 0;
  width: 100%;
  top: 0;
  z-index: 100;
  padding: 25px 0 20px;
  @include transition(all .5s);
  ul {
    list-style: none; }
  li {
    display: inline-block;
    a {
      color: #777;
      padding: 0 15px;
      @include tablet {
        padding: 0 15px; }
      @include ltablet {
        padding: 0 8px; }
      line-height: 48px;
      font-size: 14px;
      display: block; }
    &.active,&:hover {
      a {
        color: #333; } } }
  [class*="col-"]:not(.search) {
    position: static; }
  .container {
    position: relative; }
  .logo {
    position: relative;
    display: block;
    margin: 0 0 20px;
    text-align: center;
    img {
      margin: 0;
      width: 168px; } }
  nav .main-nav {
    &:after,&:before {
      content: "";
      margin: 0 15px 15px;
      display: block;
      width: 100%;
      height: 1px;
      background: rgba(255,93,177,0);
      background: -moz-linear-gradient(left, rgba(255,93,177,0) 0%, rgba(226,226,226,0.98) 41%, rgba(226,226,226,1) 42%, rgba(226,226,226,1) 57%, rgba(226,221,224,1) 58%, rgba(226,226,226,0) 100%);
      background: -webkit-gradient(left top, right top, color-stop(0%, rgba(255,93,177,0)), color-stop(41%, rgba(226,226,226,0.98)), color-stop(42%, rgba(226,226,226,1)), color-stop(57%, rgba(226,226,226,1)), color-stop(58%, rgba(226,221,224,1)), color-stop(100%, rgba(226,226,226,0)));
      background: -webkit-linear-gradient(left, rgba(255,93,177,0) 0%, rgba(226,226,226,0.98) 41%, rgba(226,226,226,1) 42%, rgba(226,226,226,1) 57%, rgba(226,221,224,1) 58%, rgba(226,226,226,0) 100%);
      background: -o-linear-gradient(left, rgba(255,93,177,0) 0%, rgba(226,226,226,0.98) 41%, rgba(226,226,226,1) 42%, rgba(226,226,226,1) 57%, rgba(226,221,224,1) 58%, rgba(226,226,226,0) 100%);
      background: -ms-linear-gradient(left, rgba(255,93,177,0) 0%, rgba(226,226,226,0.98) 41%, rgba(226,226,226,1) 42%, rgba(226,226,226,1) 57%, rgba(226,221,224,1) 58%, rgba(226,226,226,0) 100%);
      background: linear-gradient(to right, rgba(255,93,177,0) 0%, rgba(226,226,226,0.98) 41%, rgba(226,226,226,1) 42%, rgba(226,226,226,1) 57%, rgba(226,221,224,1) 58%, rgba(226,226,226,0) 100%);
      filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ff5db1', endColorstr='#ef017c', GradientType=1 ); }
    &:after {
      margin: 15px 0 0; }
    .search {
      position: relative;
      &:after {
        position: absolute;
        right: 30px;
        top: 13px;
        width: 20px;
        height: 20px;
        content: "";
        background: url('../images/icon-search.png') no-repeat;
        @include backgroundSize(100% 100%); }
      @include minltablet {
        float: right; }
      .form-control {
        height: 48px; }
      &.min {
        &:after {
          display: none; }
        b {
          position: absolute;
          right: 30px;
          top: 13px;
          width: 20px;
          height: 20px;
          content: "";
          background: url('../images/icon-search.png') no-repeat;
          @include backgroundSize(100% 100%);
          cursor: pointer; }
        @include minltablet {
          .form-control {
            transform: scaleX(0);
            transform-origin: 100% 100%;
            transition: all .5s; }
          &.expand {
            .form-control {
              transform: scaleX(1); } } } } } }

  ul.main-menu {
    @include transition(all .5s,visbility 0s);
    position: relative;
    li {
      a {
        text-transform: uppercase;
        position: relative; }
      &:first-child {
        a {
          padding-left: 0; } }


      &.dropdown {
        position: relative;
        .sub {
          visibility: hidden;
          position: absolute;
          top: 90%;
          left: calc(50% - 94px);
          width: 188px;
          z-index: 5;
          opacity: 0;
          @include transition(opacity .5s,visibility .1s,top .5s .5s);
          padding: 20px 0 0;
          background: #FFF /* rgba(33,33,33,0.9) */;
          box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.5);
          border-radius: 5px;
          padding: 25px 25px;
          display: block;
          float: none;
          &:before,&:after {
            position: absolute;
            content: "";
            border-bottom: 13px solid #FFF /* rgba(33,33,33,0.9) */;
            border-left: 11px solid transparent;
            border-right: 11px solid transparent;
            width: 0;
            height: 0;
            top: -13px;
            left: calc(50% - 11px);
            z-index: 3; }
          &:after {
            top: -14px;
            border-bottom-color: #aaa;
            z-index: 1; }
          li {
            padding: 0 !important;
            display: block !important;
            margin: 0;
            a {
              text-transform: none;
              padding: 0;
              color: #777;
              border: none;
              line-height: 24px;
              text-align: left;
              &:hover {
                opacity: 0.5;
                color: #CCC; } }
            &.active {
              a {
                @include minltablet {
                  color: $lblue !important; }
                @include maxtablet {
                  color: #fff; } } } } }
        .sub-menu {
          li {
            a {
              text-transform: uppercase; } } }

        &:hover {
          .sub {
            visibility : visible;
            opacity: 1;
            top: calc(100% + 10px);
            @include transition(all .5s); } }
        &.active {
          a {
            @include minltablet {
              color: $lblue !important; } } }

        .sub-menu {
          min-width: 900px;
          .col-sm-9 {
            border-left: 1px solid #CCC; }
          ul {
            li {
              a {
                color: #777; } } }
          @include maxtablet {
            min-width: 0;
            max-width: 100%;
            padding: 15px 0;
            ul {
              margin: 0; } }
          &:before,&:after {
            left: calc(10% - 5px); }
          h3 {
            color: #666;
            padding-bottom: 10px;
            font-weight: bold;
            font-size: 18px;
            border-bottom: 1px solid #FFF;
            margin-bottom: 0; }
          .subm-brands {
            li {
              float: left;
              width: 33.33%;
              margin-bottom: 15px;
              .img {
                margin: auto;
                display: block;
                width: auto !important;
                height: auto !important;
                max-width: 80%; } } } } }
      &.menu-item-has-children > a:hover + .sub {
        transition-delay: 0s !important; } } }
  ul.top-menu {
    margin-top: 0;
    @include minltablet {
      position: absolute;
      right: 0;
      top: 0; }

    li.cart {
      img {
        width: 22px; }
      span {
        @include minltablet {
          display: none; } }
      a {
        position: relative;
        b.c {
          width: 20px;
          height: 20px;
          background: $orange;
          text-align: center;
          line-height: 20px;
          color: #fff;
          position: absolute;
          right: 10px;
          top: 22px;
          border-radius: 100%;
          @include maxtablet {
            right: -5px; } } } }

    li {
      position: relative;
      &.cart {
        @include minltablet {
          float: right;
          padding-left: 20px;
          z-index: 3;
 } }          //left: -20px
      &.lang {
        b {
          position: relative;
          top: 3px;
          display: inline-block;
          width: 20px;
          height: 15px;
          //border-radius: 100%
          overflow: hidden;
          border: 1px solid #aaa;
          margin-right: 5px;
          img {
            display: block;
            position: relative;
            top: -2.5px; } }
        .fa {
          margin-left: 5px; }
        .sub {
          width: 100px;
          padding: 10px 0;
          left: calc(50% - 50px);
          li {
            padding: 5px 5px 10px;
            a {
              padding: 0; }
            &.active {
              background: #f9f9f9; } } }
        &:hover {
          .fa {
            transform: rotate(180deg); } } }
      .sub {
        opacity: 0;
        padding: 20px;
        visibility: hidden;
        position: absolute;
        width: 400px;
        top: calc(100%);
        left: -130%;
        background-color: #FFFFFF;
        box-shadow: 0 0 4px 0 rgba(0,0,0,0.5);
        border-radius: 2px;
        //+transition(all .5s)
        z-index: 2;
        @include minltablet {
          display: none; }
        &:before,&:after {
          content: "";
          position: absolute;
          top: -7px;
          left: calc(50% - 7px);
          width: 0;
          height: 0;
          border-bottom: 7px solid #fff;
          border-left: 7px solid transparent;
          border-right: 7px solid transparent;
          z-index: 2; }
        &:before {
          top: -8px;
          border-bottom-color: #aaa;
          z-index: 1; }
        &.account-menu.loggedin {
          width: 300px;
          margin: 0;
          left: -80%;
          padding-top: 5px; }
        &.cart-content {
          left: auto;
          right: -120%;
          &:before,&:after {
            left: calc(73% - 7px); } }
        li {
          display: block;
          a {
            font-size: 16px;
            line-height: 1; }
          &.login {
            .btn {
              width: 100%;
              display: block;
              text-align: center;
              font-size: 18px;
              line-height: 50px; } }
          &.register {
            text-align: center;
            padding-top: 20px;
            line-height: 19px;
            font-size: 16px;
            a {
              display: inline-block;
              line-height: 19px;
              padding: 0;
              font-size: 16px;
              &:hover {
                color: $orange; } } }
          &.menu {
            a {
              margin: 0 -20px;
              padding: 17px 20px;
              &:hover {
                background: #f9f9f9; } } }
          &.list-product {
            max-height: 295px;
            overflow-y: auto;
            overflow-x: hidden;
            &::-webkit-scrollbar {
              -webkit-appearance: none;
              width: 7px; }
            &::-webkit-scrollbar-thumb {
              border-radius: 4px;
              position: relative;
              right: -10px;
              background-color: rgba(0, 0, 0, 0.5);
              -webkit-box-shadow: 0 0 1px rgba(255, 255, 255, 0.5); }
            li {
              border-bottom: 1px solid #e2e2e2;
              padding: 20px 0;
              a {
                line-height: 19px;
                font-weight: 600;
                display: block;
                margin: 0 0 5px;
                padding: 0; }
              span {
                display: inline-block;
                line-height: 19px;
                font-size: 16px;
                margin-right: 19px; }
              .close {
                opacity: 1; } } }
          &.total {
            padding: 20px 0;
            font-weight: 600; }
          &.action {
            margin: 0;
            height: auto;
            min-height: 50px;
            background: none;
            .btn {
              text-align: center;
              line-height: 50px;
              width: 100%;
              &+ .btn {
                margin-top: 10px; } } }
          &.empty {
            padding: 10px;
            text-align: center; } } }

      &:hover {
        z-index: 2;
        .sub {
          opacity: 1;
          top: calc(100%);
          visibility: visible;
          @include minltablet {
            display: block; } } } } }

  @include minltablet {
    &.fixedd {
      position: fixed;
      padding: 10px 0;
      width: 100%;
      left: 0;
      top: 0 !important;
      bottom: auto !important;
      z-index: 100;
      .logo {
        margin: 0;
        height: 0;
        svg {
          width: 0px;
          height: 0;
          vibility: hidden;
          path.txt {
            fill: #fff;
            stroke: #fff;
            stroke-width: 1; } } }
      nav {
        ul {
          &.main {
            margin: 0;
            li {
              a {
                color: #fff;
                line-height: 40px;
                &:hover {
                  color: rgba(#fff,.7); } } } } } } }
    &.sticky {
      top: 0; } } }

.burger-icon {
  width: 30px;
  height: 20px;
  margin: 0 auto;
  cursor: pointer;
  position: absolute;
  display: none;
  left: 20px;
  top: -50px;
  @include transition(.25s ease-in-out);
  transition-delay: .25s;
  &:before {
    position: absolute;
    top: 0;
    left: 0;
    width: 40px;
    height: 40px;
    border-radius: 30px;
    border: 1px solid #fff;
    content: "";
    opacity: 0; }
  span {
    display: block;
    position: absolute;
    height: 1px;
    width: 30px;
    background: #212121;
    border-radius: 9px;
    opacity: 1;
    left: 0;
    @include transform(rotate(0deg));
    @include transition(opacity .5s .5s,top .5s .4s,transform .5s);
    &:nth-child(1) {
      top: 0; }
    &:nth-child(2) {
      top: 9px; }
    &:nth-child(3) {
      top: 18px; } } }

.dot-icon {
  width: 5px;
  height: 20px;
  margin: 0 auto;
  cursor: pointer;
  position: absolute;
  display: none;
  right: 15px;
  top: 12px;
  @include transition(.25s ease-in-out);
  transition-delay: .25s;
  span {
    display: block;
    position: absolute;
    height: 5px;
    width: 5px;
    border-radius: 100%;
    background: #212121;
    border-radius: 9px;
    opacity: 1;
    left: 0;
    @include transform(rotate(0deg));
    @include transition(opacity .5s .5s,top .5s .4s,transform .5s);
    &:nth-child(1) {
      top: 0; }
    &:nth-child(2) {
      top: 9px; }
    &:nth-child(3) {
      top: 18px; } } }

body.open {
  .burger-icon {
    span {
      @include transition(opacity .5s,top .5s,transform .5s .4s);
      &:nth-child(1) {
        @include transform(rotate(45deg));
        top: 9px; }
      &:nth-child(2) {
        opacity: 0; }
      &:nth-child(3) {
        @include transform(rotate(-45deg));
        top: 9px; } } } }

body.open-right {
  .dot-icon {
    @include transform(rotate(90deg)); } }

@include maxtablet {
  body {
    &:after {
      background: #3d3d3e;
      position: fixed;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      z-index: 999;
      opacity: 0;
      @include transition(all .5s);
      content: "";
      visibility: hidden; } }
  header {
    position: fixed;
    width: 100%;
    text-align: center;
    top: 0;
    bottom: auto;
    background: #fff;
    border-bottom: 2px solid #212121;
    z-index: 1000;
    @include transition(all .5s);
    padding: 10px 0;
    max-height: 100%;
    min-height: 65px;
    &.fixed {
      top: -80px; }
    &.sticky {
      top: 0; }
    .burger-icon {
      display: block;
      top: 18px;
      left: 15px;
      @include mobile {
        top: 12px; } }
    .dot-icon {
      display: block;
      @include mobile {
        top: 12px; } }
    .logo {
      position: static;
      padding: 0;
      margin: 0;
      display: inline-block;
      img {
        width: auto;
        height: 35px;
        margin: 0; } }
    nav .main-nav {
      overflow: hidden;
      max-height: 0;
      text-align: left;
      @include transition(all .5s);
      visibility: hidden;
      margin: 0 -15px;
      .search {
        margin: 0 50px;
        .form-control {
          background: #f9f9f9;
          &:focus {
            background: #fff; } } } }
    ul {
      text-align: left;
      margin: 0 50px;
      li a {
        line-height: 40px;
        border: none;
        border-bottom: 1px dotted #aaa;
        padding: 0;
        border-radius: 0 !important;
        &:hover,&:focus {
          color: #999; } }
      li {
        display: block;
        @include transform(scale(1.1) translateY(-24px));
        opacity: 0;
        @include transition(opacity 0.35s ease-out, transform 0.35s ease-out, -webkit-transform 0.35s ease-out);
        &.active {
          &> a {
            color: $lblue !important; } }
        &.dropdown > a {
          position: relative;
          &:after {
            content: "\f107";
            font-family: fontAwesome;
            position: absolute;
            right: 0;
            top: 0; } } }
      &.main-menu li {
        &.dropdown {
          ul.sub,.sub-menu {
            background: #f9f9f9;
            opacity: 1;
            visibility: visible;
            position: static;
            max-height: 0;
            overflow: hidden;
            padding: 0;
            width: 100%;
            border-radius: 0;
            box-shadow: none;
            border: none;
            &:before,&:after {
              display: none; }
            li {
              border: none;
              padding: 5px 15px !important; }
            @include maxtablet {
              background: none;
              margin: 0;
              li {
                padding: 5px 0 !important;
                a {
                  padding-left: 15px;
                  color: #777;
                  border-bottom: 1px dotted #aaa; } } } }
          .sub-menu {
            li {
              a {
                text-transform: uppercase; } } }
          &.in > a:after {
            content: "\f106"; }
          &.in {
            .sub,.sub-menu {
              max-height: 999px; }
            .sub-menu {
              @include maxtablet {
                h3 {
                  padding: 15px 0;
                  color: #777;
                  border-bottom: 1px solid #aaa; } }
              .subm-brands {
                li {
                  a {
                    padding-left: 0;
                    border-bottom: none; } } } } } } }

      &.top-menu {
        max-height: 0;
        text-align: left;
        @include transition(all .5s);
        margin: 0 15px;
        text-align: center;
        li {
          max-height: 0;
          overflow: hidden;
          a {
            text-align: left; } }
        &> li {
          &.cart {
            position: static;
            max-height: 999px;
            opacity: 1;
            transform: none;
            overflow: visible;
            &> a {
              position: absolute;
              top: 3px;
              right: 35px;
              border: none;
              text-align: center;
              font-weight: 600;
              @include transition(.25s ease-in-out);
              transition-delay: .25s;
              span {
                display: none; }
              b.ca {
                position: static;
                display: inline-block; } }
            .sub {
              position: fixed;
              top: 65px;
              left: 0;
              width: 100%;
              margin: 0;
              opacity: 1;
              visibility: visible;
              box-shadow: none;
              bordder-radius: 0;
              border: 1px solid #ccc;
              display: none;
              @include transition(all .5s);
              &:before,&:after {
                left: calc(100% - 60px);
                @include mobile {
                  left: calc(100% - 53px); } }
              li {
                opacity: 1;
                visibility: visible;
                max-height: none;
                transform: none; }
              li.list-product {
                max-height: 170px; }
              ul {
                margin: 0; } }
            &.in {
              .sub {
                display: block; } }
            .suba {
              opacity: 1;
              visibility: visible;
              position: static;
              box-shadow: none;
              margin: 0;
              width: 100%;
              border-bottom: 1px solid #ccc;
              padding: 0 0 20px;
              background: none;
              li.list-product {
                max-height: 170px; }
              li.empty {
                padding-top: 30px; }
              ul {
                margin: 0;
                li {
                  a {
                    border: none; } } }
              &:before,&:after {
                display: none; } } }
          &.my-account > a {
            position: relative;
            &:after {
              content: "\f107";
              font-family: fontAwesome;
              position: absolute;
              right: 10px;
              top: 0; } }
          &.my-account {
            .sub {
              padding: 0 !important;
              background: #f9f9f9;
              opacity: 1;
              visibility: visible;
              position: static;
              max-height: 0;
              overflow: hidden;
              padding: 0;
              width: 100%;
              border-radius: 0;
              box-shadow: none;
              border: none;
              background: none;
              margin: 0;
              &:before,&:after {
                display: none; }
              li {
                border: none;
                padding: 0 15px;
                a {
                  padding: 10px;
                  padding-left: 15px;
                  color: #777;
                  border: none; }
                .btn {
                  color: #fff;
                  padding: 0; }
                &.login {
                  padding: 15px 0; }
                &.register a {
                  padding: 0 0 10px; } } }
            &.in > a:after {
              content: "\f106"; }
            &.in {
              .sub {
                max-height: 999px; } } }
          &.lang {
            &> a {
              display: none; }
            ul.sub {
              opacity: 1;
              position: static;
              visibility: visible;
              margin: 0;
              width: 100%;
              background: transparent;
              text-align: center;
              li {
                display: inline-block;
                padding: 5px 10px 10px;
                border-radius: 5px;
                a {
                  border: none;
                  span {
 }                    //display: none
                  b {
                    //top: 0
 } }                    //margin: 0
                &.active {
                  background: none;
                  a {
                    span {
                      font-weight: 600; } } }
                &:not(:last-child) {
                  border-right: 1px solid #e2e2e2; } } } } } } } }


  body.open {
    &:after {
      opacity: 1;
      visibility: visible; }
    .dot-icon {
      right: -50px; }
    overflow: hidden;
    header {
      max-height: 100%;
      background: #eee;
      overflow-y: auto;
      overflow-x: hidden;
      ul.top-menu > li.cart > a {
        right: -50px; }
      nav .main-nav {
        max-height: 999px;
        visibility: visible;
        ul {
          li {
            opacity: 1;
            @include transform(none);
            @for $item from 1 through 15 {
              &:nth-of-type(#{$item}) {
                -webkit-transition-delay: #{$item*50+200}ms,#{$item*50+200}ms !important;
                -moz-transition-delay: #{$item*50+200}ms,#{$item*50+200}ms !important;
                -o-transition-delay: #{$item*50+200}ms,#{$item*50+200}ms !important;
                -ms-transition-delay: #{$item*50+200}ms,#{$item*50+200}ms !important;
                transition-delay: #{$item*50+200}ms,#{$item*50+200}ms !important; } } } } } } }
  body.open-right {
    &:after {
      opacity: 1;
      visibility: visible; }
    .burger-icon {
      left: -50px; }
    overflow: hidden;
    header {
      ul.top-menu > li.cart > a {
        top: -50px; }
      max-height: 100%;
      background: #eee;
      overflow-y: auto;
      overflow-x: hidden;
      nav .top-menu {
        max-height: 999px;
        visibility: visible;
        li {
          max-height: 999px;
          opacity: 1;
          @include transform(none);
          @for $item from 1 through 15 {
            &:nth-of-type(#{$item}) {
              -webkit-transition-delay: #{$item*50+200}ms,#{$item*50+200}ms !important;
              -moz-transition-delay: #{$item*50+200}ms,#{$item*50+200}ms !important;
              -o-transition-delay: #{$item*50+200}ms,#{$item*50+200}ms !important;
              -ms-transition-delay: #{$item*50+200}ms,#{$item*50+200}ms !important;
              transition-delay: #{$item*50+200}ms,#{$item*50+200}ms !important; } } } } } } }
