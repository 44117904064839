/* ------------------------------------------------------------------------------------------------ */
/* Common */
/* ------------------------------------------------------------------------------------------------ */

*, *:after, *:before {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box; }

/* Common */

hr {
  border: 0;
  border-top: 1px solid #e2e2e2; }


.clearfix {
  display: block;
  width: 100%;
  &:before,
  &:after {
    content: " ";
    display: table; }
  &:after {
    clear: both; } }

/* ------------------------------------------------------------------------------------------------ */
/* Typography */
/* ------------------------------------------------------------------------------------------------ */

a {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-weight: 200;
  color: $orange;
  text-decoration: none;
  &:focus {
    text-decoration: none; }
  &:hover {
    color: inherit;
    opacity: 1;
    text-decoration: none; } }
strong, b {
  font-weight: normal; }

p {
  margin: 0 0 10px;
  padding: 0;
  line-height: 22px;
  strong,b {
    @extend .text-bold; }
  em,i {
    font-style: italic; }
  u {
    text-decoration: underline; } }

h1, h2, h3, h4 {
  margin: 0 0 10px;
  line-height: normal; }

h1 {
  font-size: 48px; }

h3 {
  font-size: 16px; }

h4 {
  font-size: 14px; }

article {
  h1 {
    font-size: 2em; }
  /* 2*16 = 32 */
  h2 {
    font-size: 1.5em; }
  /* 1.5*16 = 24 */
  h3 {
    font-size: 1.17em; }
  /* 1.17*16 = 18.72 */
  h4 {
    font-size: 1em; }
  /* 1*16 = 16 */
  h5 {
    font-size: 0.83em; }
  /* 0.83*16 = 13.28 */
  h6 {
    font-size: 0.75em; }
  /* 0.75*16 = 12 */
  p {
    line-height: 1.5;
    margin: 0 0 1em; }
  .indent p {
    margin: 0;
    + p {
      text-indent: 2em; } }
  p {
    word-break: keep-all;
    /* Non standard for webkit */
    word-break: break-word;
    -webkit-hyphens: none;
    -moz-hyphens: none;
    -ms-hyphens: none;
    hyphens: none; }
  a {
    &:visited {
      text-decoration: underline; }
    &:hover {
      text-decoration: none; } }
  blockquote {
    font-style: italic;
    &:before {
      content: "\201C";
      display: inline-block;
      padding-right: 0.4em; } }
  li {
    ul, ol {
      margin: 0 1.5em; } }
  ul, ol {
    margin: 0; }
  ul {
    list-style-type: disc; }
  ol {
    list-style-type: decimal;
    ol {
      list-style: upper-alpha;
      ol {
        list-style: lower-roman;
        ol {
          list-style: lower-alpha; } } } }
  li {
    margin-bottom: 10px; } }

ul {
  padding: 0;
  margin: 0; }
.list-inline > li {
  padding: 0; }

label, .label {
  font-weight: normal; }


.text-bold {
  font-weight: normal;
  font-family: "proximanova-bold", "Helvetica Neue", Helvetica, Arial, sans-serif !important; }

.text-regular {
  font-weight: normal;
  font-family: "proximanova-regular", "Helvetica Neue", Helvetica, Arial, sans-serif !important; }

.text-semibold {
  font-weight: normal;
  font-family: "proximanova-semibold", "Helvetica Neue", Helvetica, Arial, sans-serif !important; }

.text-light {
  font-weight: normal;
  font-family: "proximanova-light", "Helvetica Neue", Helvetica, Arial, sans-serif !important; }

.font-size-12 {
  font-size: 12px;
  line-height: 18px; }
.font-size-13 {
  font-size: 13px;
  line-height: 18px; }

.font-size-14 {
  font-size: 14px;
  line-height: 21px; }

.font-size-16 {
  font-size: 16px;
  line-height: 24px;
  @media(max-width: 767px) {
    font-size: 14px;
    line-height: 18px; } }


.font-size-18 {
  font-size: 18px;
  line-height: 26px;
  @media(max-width: 767px) {
    font-size: 16px;
    line-height: 24px; } }

.font-size-20 {
  font-size: 20px;
  line-height: 29px;
  @include mobile {
    font-size: 16px;
    line-height: 1.5; } }

.font-size-21 {
  font-size: 21px;
  line-height: 30px;
  @media(max-width: 767px) {
    font-size: 18px;
    line-height: 30px; } }

.font-size-24 {
  font-size: 24px;
  line-height: 30px;
  @media(max-width: 767px) {
    font-size: 21px;
    line-height: 1.5; } }

.font-size-26 {
  font-size: 26px;
  line-height: 36px;
  @media(max-width: 767px) {
    font-size: 22px;
    line-height: 1.5; } }

.font-size-30 {
  font-size: 30px;
  line-height: 45px;
  @media(max-width: 767px) {
    font-size: 20px;
    line-height: 1.5em; } }
.font-size-32 {
  font-size: 32px;
  line-height: 45px;
  @media(max-width: 767px) {
    font-size: 20px;
    line-height: 1.5em; } }

.font-size-36 {
  font-size: 36px;
  line-height: 45px;
  @media(max-width: 767px) {
    font-size: 21px;
    line-height: 1.5em; } }

.font-size-42 {
  font-size: 42px;
  line-height: 1.5;
  @media(max-width: 767px) {
    font-size: 24px; } }

.font-size-46 {
  font-size: 46px;
  line-height: 1.5;
  @media(max-width: 767px) {
    font-size: 24px; } }

.font-size-48 {
  font-size: 48px;
  line-height: 55px;
  @media(max-width: 767px) {
    font-size: 24px;
    line-height: 1.5em; } }

.font-size-60 {
  font-size: 60px;
  line-height: 1.2;
  @media(max-width: 768px) {
    font-size: 30px; } }

.font-size-72 {
  font-size: 72px;
  line-height: 1.2;
  @include mobile {
    font-size: 60px; } }

.font-size-80 {
  font-size: 80px;
  line-height: 1.2;
  @include tablet {
    font-size: 50px; }
  @include mobile {
    font-size: 50px; } }

.font-size-120 {
  font-size: 120px;
  line-height: normal; }

.text-capital {
  text-transform: uppercase; }

.row.row0 {
  [class*="col-"] {
    padding: 0; } }
.row.row10 {
  text-align: center;
  margin-bottom: 30px;
  [class*="col-"] {
    float: none;
    display: inline-block;
    padding: 10px;
    margin: -0.5%;
    .img-responsive {
      margin: 0 !important;
      width: 100%; } } }

/* ------------------------------------------------------------------------------------------------ */
/* Bootstrap */
/* ------------------------------------------------------------------------------------------------ */

.form-control {
  border-color: #e2e2e2; }
.form-control:hover,
.form-control:focus {
  box-shadow: none;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  -moz-transition: none;
  -webkit-transition: none;
  border-color: #e2e2e2; }

html, body {
  @extend .font-size-16,.text-regular;
  margin: 0px;
  color: $primary;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased; }
hr {
  margin: 20px 15px;
  @include mobile() {
    margin: 10px 0; } }
