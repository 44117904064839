/* ------------------------------------------------------------------------------------------------ */
/* General */
/* ------------------------------------------------------------------------------------------------ */


.hidden {
  display: none; }

.btn {
  position: relative;
  color: $blue;
  padding: 0 15px;
  @extend .font-size-16;
  line-height: 38px;
  @include transition(all ease .5s);
  border: none;
  border-radius: 3px;
  background: #fff;
  &:hover,&:focus {
    color: #ff; }
  &.btn-transparent {
    color: #fff;
    background: none;
    border: 1px solid #fff;
    &:hover {
      color: #000;
      background: #fff; }
    &-black {
      color: #212121;
      background: #fff;
      &:hover {
        color: $orange; } } }
  &.btn-submit {
    background: #9a6735;
    color: #fff;
    width: 100%;
    &:hover {
      background: darken(#9a6735,10%); } }
  &.btn-black {
    color: #fff;
    border: 1px solid #212121;
    background: #212121;
    &:hover {
      background: #555; } }
  &.btn-orange {
    color: #fff;
    border: 1px solid #F39E00;
    background-color: #F39E00;
    &:hover {
      background: darken(#F39E00,10%); } } }



.img-responsive {
  margin: 0 auto; }

img {
  max-width: 100%; }

a {
  color: $primary;
  @include transition(all ease .5s);
  &:hover {
    color: $orange; } }
main,.modal {
  p {
    @extend .font-size-16;
    margin: 0 0 30px;
    @media(max-width: 767px) {
      margin: 0 0 15px; }
    b {
      font-weight: bold; } }
  img.h65 {
    height: 65px;
    margin: 0; }
  h2 {
    @extend .font-size-26;
    position: relative;
    margin-bottom: 20px;
    p {
      @extend .font-size-14;
      margin: 0 0 10px;
      color: $orange; }
    &.with-text {
      @extend .font-size-36,.mb80;
      text-align: center;
      p {
        color: $red;
        font-size: 14px;
        text-transform: uppercase;
        margin: 0 0 10px !important; }
      @include mobile {
        margin-bottom: 30px; } } }
  h3 {
    margin: 0 0 30px;
    @extend .font-size-21; } }



.owl-dots {
  text-align: center;
  .owl-dot {
    width: 11px;
    height: 11px;
    display: inline-block;
    border-radius: 8px;
    border: 1px solid #979797;
    margin: 0 5px;
    &.active {
      background: #fff; } }
  &.bordered {
    .owl-dot {
      border: 1px solid #000; } } }

.ot-table + .pagination {
  margin-top: -40px; }
.pagination {
  display: block;
  text-align: right;
  li {
    display: inline-block;
    a {
      border-radius: 3px !important;
      color: #000;
      padding: 0;
      width: 37px;
      height: 37px;
      line-height: 35px;
      text-align: center;
      border: 2px solid #e2e2e2;
      background: #f9f9f9;
      &:hover {
        background: #ddd; }
      &+ a {
        margin-left: 3px; }
      @include mobile {
        margin-right: 5px; } }
    &.active {
      a {
        background: $orange;
        color: #fff;
        border-color: $orange !important; }
      &:hover {
        a {
          background: darken($orange,10%); } } } } }
.row.no-float {
  text-align: center;
  &> [class*="col-"] {
    @include mintablet {
      float: none;
      display: inline-block;
      margin-right: -1%;
      vertical-align: top; } } }
.container {
  p:last-child {
    margin: 0; } }

ul.circle {
  list-style: none;
  margin: 0 0 30px;
  li {
    position: relative;
    padding-left: 25px;
    &:before {
      content: "";
      position: absolute;
      top: 10px;
      left: 0;
      width: 5px;
      height: 5px;
      border-radius: 100%;
      background: #b27a55; } } }

.dp-block {
  display: block !important; }

.zoomWrapperImage {
  img {
    max-width: 250%; } }
.zoomWrapper {
  border-color: #d8d8d8; }

.help-block {
  &.warning {
    color: red;
    &:before {
      content: "\f06a";
      font-family: FontAwesome;
      margin-right: 10px; } } }

article {
  strong,b {
    font-weight: bold; }
  ol,ul {
    padding-left: 20px; } }
