/* Made with love & passion by Appai @ Harnods*/

@import 'cores/mixin';

// Color
$red: #cc6666;
$orange : #F39E00;
$blue : #006a93;
$lblue: #21566e;
$primary : #212121;
$green: #03a381;


/* ------------------------------------------------------------------------------------------------ */
/* Core style */
/* ------------------------------------------------------------------------------------------------ */

@import 'cores/fonts';
@import 'cores/layout';
@import 'cores/base';


/* ------------------------------------------------------------------------------------------------ */
/* Component style */
/* ------------------------------------------------------------------------------------------------ */

@import 'components/general';
@import 'components/header';
@import 'components/form';
@import 'components/content';
@import 'components/footer';
@import 'components/modal';
