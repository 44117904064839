/* ------------------------------------------------------------------------------------------------ */
/* Footer */
/* ------------------------------------------------------------------------------------------------ */

footer {
  @extend .pt60,.pb30;
  background: #212121;
  h3 {
    color: #e2e2e2;
    @extend .font-size-16,.text-regular;
    margin: 0 0 10px; }
  a {
    color: #e2e2e2;
    &:hover {
      color: #777; } }
  ul {
    lst-style: none;
    li {
      line-height: 30px; } }
  [class*="col-"]:not(.menu) {
    @include mobile {
      padding: 15px; } }
  .social {
    @include mintablet {
      float: right;
      text-align: right; }
    .fa {
      display: inline-block;
      font-size: 18px;
      padding-left: 20px;
      @include mobile {
        padding-left: 0;
        padding-right: 30px; } } }
  div.menu {
    @include mintablet {
      padding-right: 0; }
    @include minltablet {
      width: calc(16.6% - 22px); }
    h3 {
      font-size: 18px;
      @include mintablet {
        margin-right: -20px; }
      @include mobile() {
        position: relative;
        margin: 0;
        &:after {
          position: absolute;
          content: "+";
          right: 5px;
          @include transition(all .5s);
          font-size: 24px;
          font-weight: bold; } } }
    ul {
      list-style: none; }
    li {
      color: #484848;
      line-height: 1;
      a {
        line-height: 30px;
        font-size: 14px;
        color: #777;
        &:hover {
          color: #e2e2e2; } }
      p {
        margin: 0 0 10px; } }
    @include mobile() {
      border-bottom: 1px dotted #d8d8d8;
      margin: 0 15px;
      padding: 10px 0;
      &.open {
        h3:after {
          @include transform(rotate(45deg)); }
        ul {
          max-height: 999px; } } }
    ul {
      padding: 0 !important;
      @include mobile() {
        max-height: 0;
        overflow: hidden;
        @include transition(all .5s);
        li {
          padding: 5px 10px; } }
      &.col-2-nav {
        @include mintablet {
          -webkit-column-count: 2;
          -moz-column-count: 2;
          column-count: 2; } } } }
  p {
    color: #777;
    margin: 0 0 10px;
    line-height: 24px;
    font-size: 14px; }
  .text {
    @include mobile {
      text-align: center; }
    div {
      display: inline-block;
      vertical-align: top;
      padding: 0 15px;
      @extend .font-size-14;
      color: #484848;
      margin-top: 50px; } }
  .right-menu {
    @include mintablet {
      float: right;
      margin: 50px 0 0;
      ul {
        padding-top: 20px;
        li {
          display: inline-block;
          a {
            display: block;
            padding: 0 12px;
            font-size: 14px;
            color: #777;
            &:hover {
              color: #e2e2e2; } }
          &:last-child {
            a {
              padding-right: 15px; } } } } }
    @include mobile {
      margin: 20px 15px 0;
      ul {
        li {
          display: inline-block;
          a {
            display: block;
            padding: 0 10px; } } } } }
  .copy {
    margin: 50px 15px 0;
    padding-top: 20px;
    border-top: 1px solid #777;
    @include mobile {
      margin: 20px 15px 0; } } }





